import React from 'react'
// import styles from './incidentSelector.module.scss'
import RPAInfo from '../../components/RPAinfo'
import { useNavigate } from 'react-router-dom';


const IncidentSelector = () => {
const navigate = useNavigate()
  
const issueHandler = (type) => {
    navigate(`/report/${type}`);
    // setIssue(type)
  }

  return (
    <div className="section-report-incident">
      <div className="container incident-flex">
        {/* RPA info */}
        <RPAInfo />
        {/* Form */}
        <div className="form-area">
          <h2 className="heading-secondary">
            Enter details about your experience
          </h2>
          <div>
            <h3 className="selectIssue">Select Issue</h3>
            <div className="issueGrid">
              <div
                className="issueItem"
                onClick={() => issueHandler('baggage')}
              >
                <img
                  className="issueIcon"
                  src={require('../../images/bags_1.PNG')}
                  alt=""
                />
                <span className="issueText">Baggage</span>
              </div>
              <div
                className="issueItem"
                onClick={() => issueHandler('cancelledFlight')}
              >
                <img
                  className="issueIcon"
                  src={require('../../images/cancelled_1.PNG')}
                  alt=""
                />
                <span className="issueText">Cancelled Flight</span>
              </div>
              <div
                className="issueItem"
                onClick={() => issueHandler('lateFlight')}
              >
                <img
                  className="issueIcon"
                  src={require('../../images/late_1.PNG')}
                  alt=""
                />
                <span className="issueText">Late Flight</span>
              </div>
              <div 
                className="issueItem"
                onClick={() => issueHandler('lateRefund')}
              >
                <img
                  className="issueIcon"
                  src={require('../../images/refund_1.PNG')}
                  alt=""
                />
                <span className="issueText">Late Refund</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IncidentSelector
