import React, { useRef } from 'react'
import '../css/navbar.css'
import '../css/general.css'
import '../css/queries.css'
import logo from '../images/DataScalp-elevate-your-vocie.PNG'
import { Link } from 'react-router-dom'

const Navbar = () => {
  const header = useRef(null)
  const btnMobileNav = useRef(null)

  const clickHandler = () => {
    header.current.classList.toggle('nav-open')
  }

  return (
    <header ref={header} className="header">
      <div className="temp-logo">
        <Link to="/">
          <img className="logo-img" src={logo} alt="" />
        </Link>
      </div>
      <nav className="main-nav">
        <ul className="main-nav-list">
          {/* Feedback */}
          {/* <li>
            <div class="dropdown">
              <span class="dropbtn">Feedback</span>
              <div class="dropdown-content">
              <Link onClick={clickHandler} to="/feedback">Dos Donts</Link>
              </div>
            </div>
          </li> */}
          <li>
            <div class="dropdown">
              <span class="dropbtn ">
                <Link onClick={clickHandler} to="/feedback">
                  Feedback
                </Link>
              </span>
            </div>
          </li>

          {/* Competition */}
          <li>
            <div class="dropdown">
              <span class="dropbtn">Competition</span>
              <div class="dropdown-content">
                <Link onClick={clickHandler} to="/challenge">
                  DataScalp Challenge
                </Link>
              </div>
            </div>
          </li>

          {/* About */}
          {/* <li>
            <div class="dropdown">
              <span class="dropbtn">About</span>
              <div class="dropdown-content">
                <Link onClick={clickHandler} to="#">
                  Founders
                </Link>
                <Link onClick={clickHandler} to="#">
                  Our Mission
                </Link>
              </div>
            </div>
          </li> */}

          {/* Contact */}
          <li>
            <div class="dropdown">
              <span class="dropbtn">Contact</span>
              <div class="dropdown-content">
                <Link onClick={clickHandler} to="media-kit">
                  Media
                </Link>
                <Link onClick={clickHandler} to="site-advertising">
                  Site Advertising
                </Link>
                <Link onClick={clickHandler} to="data-inquiry">
                  Data Inquiry
                </Link>
                <Link onClick={clickHandler} to="/go-fund-me">
                  Support DataScalp
                </Link>
              </div>
            </div>
          </li>

          {/* Report */}
          <li>
            <div class="dropdown">
              <nobr class="dropbtn ">
                <Link
                  className="signIn-Button"
                  onClick={clickHandler}
                  to="/report"
                >
                  Report Incident
                </Link>
              </nobr>
            </div>
          </li>
        </ul>
      </nav>
      <button ref={btnMobileNav} onClick={clickHandler} class="btn-mobile-nav">
        <ion-icon class="icon-mobile-nav" name="menu-outline"></ion-icon>
        <ion-icon class="icon-mobile-nav" name="close-outline"></ion-icon>
      </button>
    </header>
  )
}

export default Navbar
