import React, { useEffect } from 'react'
import RPAInfo from '../components/RPAinfo'
import '../css/styles.css'
import '../css/general.css'
import '../css/queries.css'
import { Link } from 'react-router-dom'
import delays from '../images/ontime.png'
import refund from '../images/cancel.png'
import ReactGA from 'react-ga4'
import baneer from '../images/ds- banner 3.jpg'

const HomeScreen = () => {
  useEffect(() => {
    ReactGA.send(window.location.pathname)
  }, [])

  return (
    <div className="section-report-incident">
      <div className="container incident-flex">
        {/* RPA info */}
        <RPAInfo />

        {/* Form */}
        <div>
          {/* <div className="form-area cta-container" style={{overflowY:'hidden',height:'110px'}}> */}
          <div className="form-area cta-container" >
              <img src={baneer} alt="" style={{ width: '100%', borderRadius: '25px' }}/>
            {/* <p
              className='marqueeText'
              style={{
                fontSize: '1.8rem',
                fontWeight: '600',
                textAlign: 'start',
               
              }}
            >
              <span className='mobileOnly'><strong>Look before you book -</strong> Avoid disasters by knowing which airlines
              have the best and worst track record for flight cancellations and
              delays, lost or damaged bags, and refunds. </span><strong>Report -</strong> Use your
              voice to report poor airline performance and hold airlines
              immediately accountable by revealing and distinguishing consumer
              worthy airlines
            </p> */}
          </div>
          <div className="form-area">
            <div className="text-center ">
              <Link
                className="mobile-report-incident mobile-report-incident-1"
                to="/report"
              >
                Report Incident
              </Link>
            </div>

            <div className="homescreen-grid">
              <div className="image-container">
                <div className="image-heading-container">
                  <h3 className="graph-heading">Cancellations</h3>
                  {/* <br/> */}
                  <span className="temp-subheading">
                    (Normalized)
                  </span>
                </div>
                <img
                  src={`${process.env.REACT_APP_DASHBOARD_URL}/dashboard-cancellations.png`}
                  alt=""
                  className="graph"
                />
              </div>
              <div className="image-container">
                <div className="image-heading-container">
                  <h3 className="graph-heading">Baggage Accuracy</h3>
                  {/* <br/> */}
                  <span className="temp-subheading">
                    (Normalized)
                  </span>
                </div>
                <img
                  src={`${process.env.REACT_APP_DASHBOARD_URL}/dashboard-baggages.png`}
                  alt=""
                  className="graph"
                />
              </div>
              <div className="image-container">
                <div className="image-heading-container">
                  <h3 className="graph-heading">
                    On Time Performance{' '}
                    {/* <span className="sample-graph">(Sample Graphs)</span> */}
                  </h3>
                  <span className="temp-subheading">
                    (Avg. Flight Delay per Airline Is Coming Soon)
                  </span><br/>
                  <span className="temp-subheading">
                    (Normalized)
                  </span>
                </div>
                {/* <img
                src={`${process.env.REACT_APP_DASHBOARD_URL}/dashboard-lateflights.png`}
                alt=""
                className="graph"
              /> */}
                <img src={delays} alt="" className="graph" />
              </div>
              <div className="image-container">
                <div className="image-heading-container">
                  <h3 className="graph-heading">
                    Cancellation Time to Refund{' '}
                  </h3>
                  <span className="sample-graph">(Sample Graphs)</span>{' '}<br/>
                  <span className="temp-subheading">
                    (Normalized)
                  </span>
                  {/* <span className="temp-subheading">(Coming Oct/Nov)</span> */}
                </div>
                {/* <img
                src={`${process.env.REACT_APP_DASHBOARD_URL}/dashboard-refunds.png`}
                alt=""
                className="graph"
              /> */}
                <img src={refund} alt="" className="graph" />
              </div>
              <div className=" grid-column-spread text-center">
                <Link className="mobile-report-incident" to="/report">
                  Report Incident
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div
          className="form-area info-container"
          style={{ textAlign: 'center' }}
        >
          <span className="info-text">
            Empowering travelers to report their experiences not only aids other
            travelers but also assists airlines in gathering data on potential
            areas for improvement.
          </span>
        </div>
      </div>
    </div>
  )
}

export default HomeScreen
