import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import RPAInfo from '../../components/RPAinfo'
import toast from 'react-hot-toast'
import flightIcon from '../../images/icons/icon1.svg'
import ticket from '../../images/icons/icon4.svg'
import Loader from '../../components/Loader'
import ReactGA from 'react-ga4'
import apis from './api'
import axios from 'axios'
import calender from '../../images/icons/icon6.svg'
import clock from '../../images/icons/clock.svg'
import styles from './reportIncident.module.scss'
import clock1 from '../../images/clock.png'

const ReportIncidentForm = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const issue1 = location.pathname.split('/report/')[1]

  // useState
  const [issue, setIssue] = useState(issue1)
  const [airlineName, setAirlineName] = useState('')
  const [flightNumber, setFlightNumber] = useState('')
  const [lateHours, setLateHours] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [airlineList, setAirlineList] = useState([])
  const [lateDays, setLateDays] = useState(null)

  useEffect(() => {
    setIssue(issue1)
    ReactGA.send(window.location.pathname)
    getAirlines()
  }, [issue1])

  const getAirlines = () => {
    setIsLoading(true)
    apis.fetchAirlines().then((data) => {
      setAirlineList(data)
      setIsLoading(false)
    })
  }

  //   Handler Functions
  const submitHandler = async (e) => {
    e.preventDefault()
    if (
      airlineName !== '' &&
      flightNumber !== '' &&
      issue !== 'lateRefund' &&
      !(issue === 'lateFlight' && !lateHours)
    ) {
      const res = await axios.get('https://api.ipify.org/?format=json')
      const response = await apis.createIncident({
        issue,
        difference: issue === 'lateFlight' ? lateHours * 60 : null,
        airlineName,
        clientIP: res.data.ip,
        flightNumber,
        sum: 1,
      })
      if (response === 200) {
        toast('Issue Recorded. Redirecting...')
        setTimeout(() => {
          navigate('/')
        }, 3500)
      }
    } else if (issue === 'lateRefund' && airlineName !== '' && lateDays) {
      const res = await axios.get('https://api.ipify.org/?format=json')
      const response = await apis.createIncident({
        issue,
        difference: issue === 'lateRefund' ? lateDays : null,
        airlineName,
        clientIP: res.data.ip,
      })
      if (response === 200) {
        toast('Issue Recorded. Redirecting...')
        setTimeout(() => {
          navigate('/')
        }, 3500)
      }
    } else {
      if (!airlineName) {
        toast('Please select an airline')
      }
      if (!flightNumber && issue !== 'lateRefund') {
        toast('Please enter flight number')
      }
      if (issue === 'lateFlight' && !lateHours) {
        toast('Please enter number of hours')
      }
      if (issue === 'lateRefund' && !lateDays) {
        toast('Please enter number of days')
      }
    }
  }
  const backBtnHandler = () => {
    navigate('/report')
  }

  if (isLoading) return <Loader />
  return (
    <div className="section-report-incident">
      <div className="container incident-flex">
        <RPAInfo />
        <div className="form-area">
          <h2 className="heading-secondary">
            Enter details about your experience
          </h2>
          <form className="incident-form" onSubmit={submitHandler}>
            <h3 style={{ marginBottom: 15 }} className="selectIssue">
              Selected Issue-{' '}
              {issue === 'baggage' ? (
                <span>Baggage</span>
              ) : issue === 'lateFlight' ? (
                <span>Late Flight</span>
              ) : issue === 'lateRefund' ? (
                <span>Late Refund</span>
              ) : issue === 'cancelledFlight' ? (
                <span>Cancelled Flight</span>
              ) : null}
            </h3>
            {issue === 'lateFlight' ? (
              <div className={styles.subHeadingContainer}>
                <div className={styles.titleConatiner}>
                  <img src={clock1} alt='clock icon'/>
                  <span className={styles.title}>
                    Reasons for flight delays
                  </span>
                </div>
                <ul>
                  <li className={styles.subHeading}>
                    Flight departure is late.
                  </li>
                  <li className={styles.subHeading}>
                    Arrival is late because aircraft was hovering at
                    destination.
                  </li>
                  <li className={styles.subHeading}>
                    Waiting inside airplane.
                  </li>
                </ul>
              </div>
            ) : issue === 'cancelledFlight' ? (
              <div className={styles.subHeadingContainer}>
                <div className={styles.titleConatiner}>
                  <img src={clock1} alt='clock icon'/>
                  <span className={styles.title}>
                    Reasons for flight cancellations
                  </span>
                </div>
                <ul>
                  <li className={styles.subHeading}>
                    Flight Cancellations for all air travelers.
                  </li>
                  <li className={styles.subHeading}>
                    Air traveler's seats given to someone else.
                  </li>
                </ul>
              </div>
            ) : null}

            {/* Airline Name */}
            <label htmlFor="airline-name" className="icon-label">
              <div>
                <img src={flightIcon} alt="" />
                <span className="label-name">Airline</span>
              </div>
            </label>
            <select
              value={airlineName}
              onChange={(e) => setAirlineName(e.target.value)}
            >
              <option value="" disabled hidden>
                Select Airline
              </option>

              {airlineList.map((x) => (
                <option key={x.IATA} value={x.Airline_Name}>
                  {x.Airline_Name}
                </option>
              ))}
            </select>

            {/* Flight Number */}
            {issue !== 'lateRefund' && (
              <div>
                {' '}
                <label htmlFor="flight-number" className="icon-label">
                  <div>
                    <img src={ticket} alt="" />
                    <span className="label-name">Flight #</span>
                  </div>

                  <div>
                    <span className="popup">i</span>
                    <div class="popup-hide">
                      <div class="cover">
                        <div class="arrow-left"></div>
                      </div>
                      <p className="tooltip_text">
                        This is essential information. Please enter numeric
                        flight number (no letters) that can be found by:
                      </p>
                      <ul className="tooltip_list">
                        <li className="tooltip_list_item">
                          Text message reminder{' '}
                        </li>
                        <li className="tooltip_list_item">Calendar events</li>
                        <li className="tooltip_list_item">
                          Confirmation email
                        </li>
                        <li className="tooltip_list_item">eTicket</li>
                        <li className="tooltip_list_item">Boarding pass</li>
                      </ul>
                      <p className="tooltip_text">
                        Without the flight # it would be unfair to airlines To
                        record your report
                      </p>
                    </div>
                  </div>
                </label>
                <input
                  placeholder="Enter Number Only"
                  name="flight-number"
                  type="number"
                  value={flightNumber}
                  onChange={(e) => setFlightNumber(e.target.value)}
                  onWheel={(e) => e.target.blur()}
                />
              </div>
            )}

            {/* Late Flight */}
            {issue === 'lateFlight' && (
              <>
                <label className="icon-label">
                  <div>
                    <img
                      style={{ height: 20, width: 20, verticalAlign: 'middle' }}
                      src={clock}
                      alt=""
                    />
                    <span className="label-name">Lateness</span>
                  </div>
                </label>
                <input
                  placeholder="Hours after expected time"
                  type="number"
                  value={lateHours}
                  onChange={(e) => setLateHours(e.target.value)}
                  onWheel={(e) => e.target.blur()}
                />
              </>
            )}

            {/* Late Refund */}
            {issue === 'lateRefund' && (
              <>
                <label className="icon-label">
                  <div>
                    <img src={calender} alt="" />
                    <span className="label-name">Days to refund</span>
                  </div>
                </label>
                <input
                  placeholder="Enter days"
                  type="number"
                  value={lateDays}
                  onChange={(e) => setLateDays(e.target.value)}
                  onWheel={(e) => e.target.blur()}
                />
              </>
            )}

            {/* Submit Button */}
            <div className="buttonContainer">
              <button type="submit" className="incident-submit-button">
                Report
              </button>
              <button onClick={backBtnHandler} className="incident-back-button">
                Back
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ReportIncidentForm
