import axios from 'axios'

const apis = {
  fetchAirlines: async () => {
    const config = {
      withCredentials: false,
    }
    const res = await axios.get(
      `${process.env.REACT_APP_ADDRESS}/api/airlines/fetch`,
      config
    )
    return res.data
  },
  createIncident: async (data) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    
    const res = await axios.post(
      `${process.env.REACT_APP_ADDRESS}/api/incidents/create`,
      data,
      config
    )
    console.log('resultzz', data, res)
    return res.status
  },
}

export default apis
